.Buttons{
    position: absolute;
    bottom: 0;
    left: 10px;
    width: 100%;
    display: flex;
    justify-content: left;
    z-index: 99999;
}
.zoomBtn{
    bottom: 40px;
}
.imagetemplate{
    width: 100%;
    position: relative;
    z-index: 9; 
    /* display: none; */
    
}